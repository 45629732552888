<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">INVOICE</h2></div>
      <div class="box"><el-button @click="$onCommandParams('invoices')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <div class="flex gaps">
      <div class="box">
        <el-card class="ph-5 mr-15">
           <div class="invoice-top mb-20 flex justify-space-between">
               <div class="box self-flex-end ml-10">
                  <h3 class="mv-0 pb-10">Invoice #{{invoice.uid}}</h3>
                   <p class="o-050 mv-0 ph-10">Open - {{dateFormat(invoice.openDate)}}</p>
                   <p class="o-050 mv-0 ph-10">Close - {{dateFormat(invoice.closeDate)}}</p>
                   <p class="o-050 mv-0 ph-10">POS - {{invoice.posName}}</p>
                   <p class="o-050 mv-0 ph-10">Operator - {{invoice.author}}</p>
               </div>
               <div class="box self-flex-end top mr-10">
                 <el-button v-if="invoice.hasOwnProperty('isRefund') && !invoice.isRefund && !invoice.hasOwnProperty('memo')" type="text" size="small" @click="onClickRefund">Refund</el-button>
                 <i v-else class="mdi accent-text fs-50 refund mdi-alert-octagram">Refunded</i>
               </div>
           </div>
           <div class="invoice-bottom">
                 <el-table
                    class="box"
                    ref="basket"
                    :row-class-name="tableRowClassName"
                    @cell-click="cellClick"
                    :max-height="tableHeight"
                    :data="filtered"
                    style="width: 100%;">
                    <el-table-column
                        width="28"
                        class-name="hint-text"
                        type="index">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        label="Desc">
                        <template #default="scope">
                          <app-desc-ticket
                            :row="scope.row"
                            v-if="scope.row.category === 'Tickets' ||
                              scope.row.category === 'Books'"></app-desc-ticket>
                          <app-desc-waiver
                            :row="scope.row"
                            v-if="scope.row.category === 'Waivers'"></app-desc-waiver>
                          <app-desc-lesson
                            :row="scope.row"
                            v-if="['Lessons', 'Subscriptions'].includes(scope.row.category)"></app-desc-lesson>
                          <app-desc-snack
                            :row="scope.row"
                            v-if="['Snacks', 'Merch', 'Addons'].includes(scope.row.category)"></app-desc-snack>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="80"
                        align="right"
                        prop="times"
                        label="times">
                    </el-table-column>
                    <el-table-column
                        width="80"
                        align="right"
                        prop="rate"
                        label="rate">
                    </el-table-column>
                    <el-table-column
                        width="80"
                        align="right"
                        label="sub-total">
                        <template #default="scope">
                            <div>{{scope.row.rate * scope.row.times}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            <div class="legalcopy flex justify-flex-end mt-16 mr-16" v-if="outstanding > 0">
               <el-button type="primary" @click="onClickCard()">Card</el-button>
               <el-button type="primary" @click="onClickCash()" plain>Сash</el-button>
           </div>
           </div>
        </el-card>
      </div>
      <div class="box flex column gaps">
        <div class="box">
          <el-card class="ph-5 mr-15">
              <div class="invoice-bottom">
                <div class="flex column ph-20 fs-16" style="min-width:400px;">
                  <div class="box mv-4 flex justify-space-between"><h3 class="mv-0 o-050">Total: </h3> <span>{{invoice.total}}</span></div>
                  <div class="box mv-4 flex justify-space-between"
                    v-for="(value, name) in invoice.totalByPaymentMethod" :key="name"
                    ><h3 class="mv-0 o-050" style="text-transform: capitalize;">{{name}}: </h3><span>{{value}}</span></div>
                  <div class="box mv-4 flex justify-space-between"
                    v-for="(value, name) in invoice.totalByCompanies" :key="name"
                    ><h3 class="mv-0 o-050">{{name}}: </h3><span>{{value}}</span></div>
                  <div class="box mv-4 flex accent-text justify-space-between"
                    v-if="outstanding !== 0"
                    ><h3 class="mv-0">Outstanding: </h3><span>{{outstanding}}</span></div>
                  <div class="box mv-4 flex justify-space-between"
                    v-if="invoice.hasOwnProperty('memo')"
                    ><h3 class="mv-0 o-050">Binding: </h3><el-button type="text" size="small" @click="$onCommandParams('invoice', { id: invoice.memo })">link {{invoice.memo}}</el-button></div>
                </div>
              </div>
          </el-card>
        </div>
        <div class="box" v-if="selectedCategory.category !== ''">
          <el-card class="ph-5 mr-15">

            <div v-if="selectedCategory.category === 'Waivers'">
              <div class="invoice-top mb-20 flex justify-space-between">
                <div class="box self-flex-end ml-10">
                    <h3 class="mv-0">Waivers</h3>
                </div>
              </div>
              <div class="invoice-bottom">
                <div class="flex column ph-20 fs-16" style="min-width:400px;">
                  <div class="box info-text mv-4"
                    v-for="(w, index) in invoice.basket[selectedCategory.index].desc" :key="index + '-' + w">{{w.slice(0,4) === 'mem:' ? w.slice(4) : w}}</div>
                </div>
              </div>
            </div>

            <div v-if="selectedCategory.category === 'Lessons'">
              <div class="invoice-top mb-20 flex justify-space-between">
                <div class="box self-flex-end ml-10">
                    <h3 class="mv-0">Lessons</h3>
                </div>
              </div>
              <div class="invoice-bottom">
                <div class="flex column ph-20 fs-16" style="min-width:400px;">
                  <div class="box mv-4 flex justify-space-between"><strong>Name: </strong> <span>{{invoice.basket[selectedCategory.index].desc[0]}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Client: </strong><span class="info-text">{{invoice.basket[selectedCategory.index].customerName}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Rate: </strong><span>{{invoice.basket[selectedCategory.index].rate}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Times: </strong><span>{{invoice.basket[selectedCategory.index].times}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Total: </strong><span>{{invoice.basket[selectedCategory.index].times * invoice.basket[selectedCategory.index].rate}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Company:  </strong><span class="warning-text">{{invoice.basket[selectedCategory.index].companyName}}</span></div>
                </div>
              </div>
            </div>

            <div v-if="selectedCategory.category === 'Subscriptions'">
              <div class="invoice-top mb-20 flex justify-space-between">
                <div class="box self-flex-end ml-10">
                    <h3 class="mv-0">Subscriptions</h3>
                </div>
              </div>
              <div class="invoice-bottom">
                <div class="flex column ph-20 fs-16" style="min-width:400px;">
                  <div class="box mv-4 flex justify-space-between"><strong>Name: </strong> <span>{{invoice.basket[selectedCategory.index].desc[0]}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Client: </strong><span class="info-text">{{invoice.basket[selectedCategory.index].customerName}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Rate: </strong><span>{{invoice.basket[selectedCategory.index].rate}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Times: </strong><span>{{invoice.basket[selectedCategory.index].times}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Total: </strong><span>{{invoice.basket[selectedCategory.index].times * invoice.basket[selectedCategory.index].rate}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Company:  </strong><span class="warning-text">{{invoice.basket[selectedCategory.index].companyName}}</span></div>
                </div>
              </div>
            </div>

            <div v-if="['Addons', 'Merch', 'Snacks'].includes(selectedCategory.category)">
              <div class="invoice-top mb-20 flex justify-space-between">
                <div class="box self-flex-end ml-10">
                    <h3 class="mv-0">{{selectedCategory.category}}</h3>
                </div>
              </div>
              <div class="invoice-bottom">
                <div class="flex column ph-20 fs-16" style="min-width:400px;">
                  <div class="box mv-4 flex justify-space-between"><strong>Name: </strong> <span>{{invoice.basket[selectedCategory.index].name}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Desc: </strong> <span>{{invoice.basket[selectedCategory.index].desc[0]}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Rate: </strong><span>{{invoice.basket[selectedCategory.index].rate}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Times: </strong><span>{{invoice.basket[selectedCategory.index].times}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Total: </strong><span>{{invoice.basket[selectedCategory.index].times * invoice.basket[selectedCategory.index].rate}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Company:  </strong><span class="warning-text">{{invoice.basket[selectedCategory.index].companyName}}</span></div>
                </div>
              </div>
            </div>

            <div v-if="selectedCategory.category === 'Tickets' || selectedCategory.category === 'Books'">
              <div class="invoice-top mb-20 flex justify-space-between">
                <div class="box self-flex-end ml-10">
                    <h3 class="mv-0">{{selectedCategory.category}}</h3>
                </div>
              </div>
              <div class="invoice-bottom">
                <div class="flex column ph-20 fs-16" style="min-width:400px;">
                  <div class="box mv-4 flex justify-space-between"><strong>Name: </strong> <span>{{invoice.basket[selectedCategory.index].name}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Day: </strong> <span>{{invoice.basket[selectedCategory.index].day}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Session: </strong> <span>{{invoice.basket[selectedCategory.index].sessionName}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Time: </strong> <span>{{invoice.basket[selectedCategory.index].sessionTime}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Type: </strong> <span>{{invoice.basket[selectedCategory.index].typeName}}</span></div>
                  <div class="box mv-4 flex justify-space-between flex">
                    <strong>Client: </strong>
                    <div class="box flex column">
                      <template v-if="invoice.basket[selectedCategory.index].hasOwnProperty('clientNames')">
                        <template v-if="invoice.basket[selectedCategory.index].clientNames.length > 0">
                          <span class="box ellipsis info-text"
                            v-for="(name, index) in invoice.basket[selectedCategory.index].clientNames"
                            :key="index + '-name'">{{name}}</span>
                        </template>
                        <template v-if="invoice.basket[selectedCategory.index].times - invoice.basket[selectedCategory.index].clientNames.length > 0">
                          <span class="box ellipsis danger-text"
                            v-for="(i, index) in (invoice.basket[selectedCategory.index].times - invoice.basket[selectedCategory.index].clientNames.length)"
                            :key="index + '-no-waiver'">No waiver</span>
                        </template>
                      </template>
                      <template v-else>
                                <template v-if="invoice.basket[selectedCategory.index].hasOwnProperty('person') && invoice.basket[selectedCategory.index].person !== ''">
                                  <span class="info-text">{{getClient(invoice.basket[selectedCategory.index].desc)}}</span>
                                </template>
                                <template v-if="invoice.basket[selectedCategory.index].hasOwnProperty('personNames')">
                                  <span class="info-text"
                                    v-for="name in invoice.basket[selectedCategory.index].personNames" :key="name">{{name}}</span>
                                </template>
                                <template v-if="noWaiversCountMethod(invoice.basket[selectedCategory.index]) > 0">
                                  <span class="danger-text"
                                    v-for="(i, index) in noWaiversCountMethod(invoice.basket[selectedCategory.index])" :key="i + 'no waiver' + index">No waiver</span>
                                </template>
                      </template>

                    </div>
                  </div>
                  <div class="box mv-4 flex justify-space-between"><strong>Rate: </strong><span>{{invoice.basket[selectedCategory.index].rate}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Times: </strong><span>{{invoice.basket[selectedCategory.index].times}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Discount: </strong><span>{{invoice.basket[selectedCategory.index].discountPercent + '%'}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Total: </strong><span>{{invoice.basket[selectedCategory.index].times * invoice.basket[selectedCategory.index].rate}}</span></div>
                  <div class="box mv-4 flex justify-space-between"><strong>Company:  </strong><span class="warning-text">{{invoice.basket[selectedCategory.index].companyName}}</span></div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>

    <el-dialog title="Сonfirmation of refund" :visible.sync="dialogRefundVisible">
      <div class="fs-18">
        <span class="">Are you sure you want to refund invoice?</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogRefundVisible = false">Cancel</el-button>
        <el-button type="primary" @click="ConfirmRefund">Confirm Refund</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Сonfirmation of payment" :visible.sync="dialogConfirmVisible">
      <div class="fs-18">
        <span class="">Are you sure you want to deposit</span>
        <span><el-input-number :min="0" :max="outstanding" class="pay-left ph-9" size="small" :controls="false" v-model="form.pay"></el-input-number>AED</span>
        <br><span><strong class="accent-text pl-5">by cash</strong> to pay the bill</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirmVisible = false">Cancel</el-button>
        <el-button type="primary" @click="ConfirmCashPayment">Confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Сonfirmation of payment" :visible.sync="dialogCardVisible">
      <div class="fs-18">
        <span class="">Are you sure you want to deposit</span>
        <span><el-input-number :min="0" :max="outstanding" class="pay-left ph-9" size="small" :controls="false" v-model="form.pay"></el-input-number>AED</span>
        <br><span><strong class="accent-text pl-5">by card</strong> to pay the bill</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCardVisible = false">Cancel</el-button>
        <el-button type="primary" @click="ConfirmCardPayment">Confirm</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppDescTicket from '../../operator/sales/components/BasketDescTicket'
import AppDescWaiver from '../../operator/sales/components/BasketDescWaiver'
import AppDescLesson from '../../operator/sales/components/BasketDescLesson'
import AppDescSnack from '../../operator/sales/components/BasketDescSnack'

export default {
  name: 'Invoice',

  components: {
    AppDescTicket,
    AppDescWaiver,
    AppDescLesson,
    AppDescSnack
  },

  data () {
    return {
      dialogRefundVisible: false,
      dialogConfirmVisible: false,
      dialogCardVisible: false,
      selectedCategory: { category: '', id: -1 },
      invoice: {
        uid: -1,
        openDate: 0,
        closeDate: 0,
        basket: [],
        totalByPaymentMethod: {
          cash: 0,
          card: 0
        },
        total: 0,
        paid: 0,
        isRefund: true
      },
      form: {
        pay: 0
      }
    }
  },

  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight
    }),
    ...mapGetters([
      'getLessonById',
      'getSubscriptionById',
      'getEventById',
      'getItemById',
      'members'
    ]),
    filtered () {
      return this.invoice.hasOwnProperty('basket') ? this.invoice.basket.filter(c => c.times > 0) : []
    },
    outstanding () {
      return this.invoice.total - this.invoice.paid
    },
    tableHeight () {
      return this.innerHeight - 323
    }
  },
  methods: {
    ...mapActions([
      'refundTicket',
      'addMemTransaction',
      'editEvent',
      'addTransaction',
      'editInvoice',
      'refundedInvoice',
      'closeInvoice',
      'invoiceByUid'
    ]),
    noWaiversCountMethod (row) {
      let persons = row.hasOwnProperty('personUids') ? row.personUids.length : 0
      let person = row.hasOwnProperty('person') && row.person !== '' ? 1 : 0
      return row.times - persons - person
    },
    onClickRefund () {
      this.dialogRefundVisible = true
    },
    async ConfirmRefund () {
      await this.invoice.basket.forEach(async (row) => {
        if (row.times > 0) {
          switch (row.category) {
            case 'Tickets':
              await this.refundTicket(this.invoice.uid)

              if (row.discountType === 'Subscription') {
                let modelSubscription = {
                  property: 'subscription',
                  memberId: row.clientIds[0],
                  count: 1,
                  eventType: 'Refund Use Ticket',
                  memo: 'Refund Invoice #' + this.invoice.uid
                }
                await this.addMemTransaction(modelSubscription)
              }
              if (row.discountType === 'Lesson') {
                let modelSubscription = {
                  property: 'lesson',
                  memberId: row.clientIds[0],
                  count: 1,
                  eventType: 'Refund Use Ticket',
                  memo: 'Refund Invoice #' + this.invoice.uid
                }
                await this.addMemTransaction(modelSubscription)
              }
              break
            case 'Lessons':
              let lesson = this.getLessonById[row.eventId]
              let modelLesson = {
                property: 'lesson',
                memberId: row.customerId,
                count: 0 - lesson.duration,
                eventType: 'refund',
                memo: 'Refund Invoice #' + this.invoice.uid
              }
              await this.addMemTransaction(modelLesson)
              break
            case 'Subscriptions':
              let subscription = this.getSubscriptionById(row.eventId)
              let modelSubscription = {
                property: 'subscription',
                memberId: row.customerId,
                count: 0 - subscription.duration,
                eventType: 'refund',
                memo: 'Refund Invoice #' + this.invoice.uid
              }
              await this.addMemTransaction(modelSubscription)
              break
            case 'Events':
              let event = this.getEventById[row.eventId]
              let contentEvents = Object.assign({}, event, { prepaid: event.prepaid - row.rate, isClose: false })
              this.editEvent({ id: row.eventId, content: contentEvents })
              break
            case 'Addons':
              if (!this.getItemById[row.itemId].isRent) {
                let modelAddons = {
                  name: row.name,
                  itemId: row.itemId,
                  count: row.times,
                  price: row.rate,
                  eventType: 'refund',
                  memo: 'Refund Invoice #' + this.invoice.uid,
                  date: Date.now()
                }
                await this.addTransaction(modelAddons)
              }
              break
            case 'Merch':
              let modelMerch = {
                name: row.name,
                itemId: row.itemId,
                count: row.times,
                price: row.rate,
                eventType: 'refund',
                memo: 'Refund Invoice #' + this.invoice.uid,
                date: Date.now()
              }
              await this.addTransaction(modelMerch)
              break
            case 'Snacks':
              const modelSnacks = {
                name: row.name,
                itemId: row.itemId,
                count: row.times,
                price: row.rate,
                eventType: 'refund',
                memo: 'Refund Invoice #' + this.invoice.uid,
                date: Date.now()
              }
              await this.addTransaction(modelSnacks)
              break
          }
        }
      })
      await this.editInvoice({
        id: this.invoice.uid,
        content: {
          isRefund: true
        }
      }).then(() => {
        let totalByCompanies = {}
        for (const [key, value] of Object.entries(this.invoice.totalByCompanies)) {
          totalByCompanies[key] = 0 - value
        }
        let totalByPaymentMethod = {}
        for (const [key, value] of Object.entries(this.invoice.totalByPaymentMethod)) {
          totalByPaymentMethod[key] = 0 - value
        }
        return this.refundedInvoice({
          id: this.invoice.uid,
          content: {
            total: 0 - this.invoice.total,
            totalByCompanies: totalByCompanies,
            totalByPaymentMethod: totalByPaymentMethod,
            paid: 0 - this.invoice.paid
          }
        })
      }).then(async () => {
        this.invoice = await this.invoiceByUid(this.$route.params.id)
      }).then(() => {
        this.dialogRefundVisible = false
      }).then(() => {
        this.$message({
          showClose: true,
          message: 'Invoice refunded successfully',
          type: 'success'
        })
      })
    },
    dateFormat (date) {
      let today = new Date(date)
      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      return today.toLocaleString('en-GB', options)
    },
    getClient (descArray) {
      let client = descArray.find(d => d.slice(0, 4) === 'mem:')
      return client ? client.slice(4) : ''
    },
    getDiscount (descArray) {
      let dis = descArray.find(d => d.slice(0, 4) === 'dis:')
      return dis !== undefined ? dis.slice(4) : ''
    },
    tableRowClassName ({ row, rowIndex }) {
      if ((['Waivers', 'Addons', 'Merch', 'Snacks'].includes(row.category) && this.selectedCategory.category === row.category) ||
       (['Lessons', 'Subscriptions', 'Tickets', 'Books'].includes(row.category) && this.selectedCategory.category === row.category && row.basketId === this.selectedCategory.id) ||
       (['Events'].includes(row.category) && this.selectedCategory.category === row.category && row.eventId === this.selectedCategory.id) ||
       (row.category === 'Subscriptions' && row.basketId === this.$route.params.id)) {
        return 'warning-row'
      } else {
        return ''
      }
    },
    cellClick (row, column, cell, event) {
      this.selectedCategory = { category: row.category, id: row.basketId, index: this.filtered.indexOf(row) }
    },
    onClickCard () {
      this.dialogCardVisible = true
      this.form.pay = this.outstanding
    },
    onClickCash () {
      this.dialogConfirmVisible = true
      this.form.pay = this.outstanding
    },
    async ConfirmCashPayment () {
      let cash = this.invoice.hasOwnProperty('totalByPaymentMethod') && this.invoice.totalByPaymentMethod.hasOwnProperty('cash') ? this.invoice.totalByPaymentMethod.cash : 0
      let card = this.invoice.hasOwnProperty('totalByPaymentMethod') && this.invoice.totalByPaymentMethod.hasOwnProperty('card') ? this.invoice.totalByPaymentMethod.card : 0
      if (this.form.pay === this.outstanding) {
        await this.closeInvoice({
          id: this.invoice.uid,
          content: {
            basket: this.invoice.basket,
            total: this.invoice.total,
            totalByCompanies: this.invoice.totalByCompanies,
            totalByPaymentMethod: {
              cash: cash + this.form.pay,
              card
            },
            paid: this.invoice.hasOwnProperty('paid') ? this.invoice.paid + this.form.pay : this.form.pay,
            customerId: this.invoice.customerId,
            customerName: this.invoice.customerName,
            customerFirst: this.invoice.customerFirst,
            customerEmail: this.invoice.customerEmail
          }
        })
          .then(() => {
            this.dialogConfirmVisible = false
          })
      } else {
        await this.editInvoice({
          id: this.invoice.uid,
          content: {
            basket: this.invoice.basket,
            total: this.invoice.total,
            totalByCompanies: this.invoice.totalByCompanies,
            totalByPaymentMethod: {
              cash: cash + this.form.pay,
              card
            },
            paid: this.invoice.hasOwnProperty('paid')
              ? this.invoice.paid + this.form.pay
              : this.form.pay,
            customerId: this.invoice.customerId,
            customerName: this.invoice.customerName,
            customerFirst: this.invoice.customerFirst,
            customerEmail: this.invoice.customerEmail
          }
        }).then(() => {
          this.dialogConfirmVisible = false
        })
      }
    },
    async ConfirmCardPayment () {
      let cash = this.invoice.hasOwnProperty('totalByPaymentMethod') && this.invoice.totalByPaymentMethod.hasOwnProperty('cash') ? this.invoice.totalByPaymentMethod.cash : 0
      let card = this.invoice.hasOwnProperty('totalByPaymentMethod') && this.invoice.totalByPaymentMethod.hasOwnProperty('card') ? this.invoice.totalByPaymentMethod.card : 0
      if (this.form.pay === this.outstanding) {
        await this.closeInvoice({
          id: this.invoice.uid,
          content: {
            basket: this.invoice.basket,
            total: this.invoice.total,
            totalByCompanies: this.invoice.totalByCompanies,
            totalByPaymentMethod: {
              cash,
              card: card + this.form.pay
            },
            paid: this.invoice.hasOwnProperty('paid')
              ? this.invoice.paid + this.form.pay
              : this.form.pay,
            customerId: this.invoice.customerId,
            customerName: this.invoice.customerName,
            customerFirst: this.invoice.customerFirst,
            customerEmail: this.invoice.customerEmail
          }
        })
          .then(() => {
            this.dialogCardVisible = false
          })
      } else {
        await this.editInvoice({
          id: this.invoice.uid,
          content: {
            basket: this.invoice.basket,
            total: this.invoice.total,
            totalByCompanies: this.invoice.totalByCompanies,
            totalByPaymentMethod: {
              cash,
              card: card + this.form.pay
            },
            paid: this.invoice.hasOwnProperty('paid')
              ? this.invoice.paid + this.form.pay
              : this.form.pay,
            customerId: this.invoice.customerId,
            customerName: this.invoice.customerName,
            customerFirst: this.invoice.customerFirst,
            customerEmail: this.invoice.customerEmail
          }
        }).then(() => {
          this.dialogCardVisible = false
        })
      }
    }
  },
  async mounted () {
    let temp = await this.invoiceByUid(this.$route.params.id)
    let basket = temp.basket.filter(b => b.times > 0)
    this.invoice = Object.assign({}, temp, { basket })
  }
}
</script>
